import React from 'react';
import {PricingSection,PricingHeadingWrapper,PricingHeading,
    PricingPlanCol,PricingSpan,
    PricingPlanLine,PricingPlanBtn,PricingPlanDarkText,PricingPlanColCenter,
    PricingPlanCenterLine,PricingPlanCenterText,PricingPlanBtnCenter
} from './pricing.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';

const Pricing = ({data}) =>{
    const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF";

    return (
        <PricingSection id="pricingContainer" style={{background: color}}>
            <Container>
                <Row>
                    <Col md="12">
                        <PricingHeadingWrapper>
                            <Fade bottom delay={1*data.DelayConstant}>
                                <PricingHeading>
                                    {data.PricingHeading}
                                </PricingHeading>
                            </Fade>
                            <Fade bottom delay={2*data.DelayConstant}>
                                <p>
                                    {data.PricingSubHeading}
                                </p>
                            </Fade>
                        </PricingHeadingWrapper>
                    </Col>
                </Row>

                <Row>
                    <Col md="4">
                        <Fade bottom delay={(0+1)*data.DelayConstant}>
                            <PricingPlanCol>
                                <h3>{data.PricingPlanCol[0].PricingPlanHeading}</h3>
                                <h1>{data.PricingPlanCol[0].PricingPlanPrice} / <PricingSpan>{data.PricingPlanCol[0].PricingPlanPriceSpan}</PricingSpan></h1>
                                <PricingPlanLine/>
                                {
                                    
                                    data.PricingPlanCol[0].PricingPlanDarkText ? (
                                        data.PricingPlanCol[0].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                    ) : null
                                    
                                }
                                <PricingPlanBtn href={data.PricingPlanCol[0].PricingPlanBtnHref} target="_blank">{data.PricingPlanCol[0].PricingPlanBtn}</PricingPlanBtn>
                            </PricingPlanCol>
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom delay={(1+1)*data.DelayConstant}>
                            <PricingPlanColCenter>
                                <h3>{data.PricingPlanCol[1].PricingPlanHeading}</h3>
                                <h1>{data.PricingPlanCol[1].PricingPlanPrice} / <PricingSpan>{data.PricingPlanCol[1].PricingPlanPriceSpan}</PricingSpan></h1>
                                <PricingPlanCenterLine/>
                                {
                                    
                                    data.PricingPlanCol[1].PricingPlanDarkText ? (
                                        data.PricingPlanCol[1].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanCenterText>{itemDark.Text}</PricingPlanCenterText>
                                    })
                                    ) : null
                                    
                                }
                                <PricingPlanBtnCenter href={data.PricingPlanCol[1].PricingPlanBtnHref} target="_blank">{data.PricingPlanCol[1].PricingPlanBtn}</PricingPlanBtnCenter>
                            </PricingPlanColCenter>
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom delay={(2+1)*data.DelayConstant}>
                            <PricingPlanCol>
                                <h3>{data.PricingPlanCol[2].PricingPlanHeading}</h3>
                                <h1>{data.PricingPlanCol[2].PricingPlanPrice} / <PricingSpan>{data.PricingPlanCol[2].PricingPlanPriceSpan}</PricingSpan></h1>
                                <PricingPlanLine/>
                                {
                                    
                                    data.PricingPlanCol[2].PricingPlanDarkText ? (
                                        data.PricingPlanCol[2].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                    ) : null
                                    
                                }
                                <PricingPlanBtn href={data.PricingPlanCol[2].PricingPlanBtnHref} target="_blank">{data.PricingPlanCol[2].PricingPlanBtn}</PricingPlanBtn>
                            </PricingPlanCol>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </PricingSection>
    );
}

export default Pricing;
